@font-face {
  font-family: 'Proba Pro Lt';
  src: url('ProbaPro-Light.eot');
  src: local('Proba Pro Light'), local('ProbaPro-Light'),
    url('ProbaPro-Light.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-Light.woff2') format('woff2'), url('ProbaPro-Light.woff') format('woff'),
    url('ProbaPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro SmBd';
  src: url('ProbaPro-SemiBoldItalic.eot');
  src: local('Proba Pro SemiBold Italic'), local('ProbaPro-SemiBoldItalic'),
    url('ProbaPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-SemiBoldItalic.woff2') format('woff2'),
    url('ProbaPro-SemiBoldItalic.woff') format('woff'),
    url('ProbaPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proba Pro ExLt';
  src: url('ProbaPro-ExtraLight.eot');
  src: local('Proba Pro ExtraLight'), local('ProbaPro-ExtraLight'),
    url('ProbaPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-ExtraLight.woff2') format('woff2'), url('ProbaPro-ExtraLight.woff') format('woff'),
    url('ProbaPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('ProbaPro-Bold.eot');
  src: local('Proba Pro Bold'), local('ProbaPro-Bold'),
    url('ProbaPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-Bold.woff2') format('woff2'), url('ProbaPro-Bold.woff') format('woff'),
    url('ProbaPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro Md';
  src: url('ProbaPro-Medium.eot');
  src: local('Proba Pro Medium'), local('ProbaPro-Medium'),
    url('ProbaPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-Medium.woff2') format('woff2'), url('ProbaPro-Medium.woff') format('woff'),
    url('ProbaPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro Th';
  src: url('ProbaPro-Thin.eot');
  src: local('Proba Pro Thin'), local('ProbaPro-Thin'),
    url('ProbaPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-Thin.woff2') format('woff2'), url('ProbaPro-Thin.woff') format('woff'),
    url('ProbaPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro ExLt';
  src: url('ProbaPro-ExtraLightItalic.eot');
  src: local('Proba Pro ExtraLight Italic'), local('ProbaPro-ExtraLightItalic'),
    url('ProbaPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-ExtraLightItalic.woff2') format('woff2'),
    url('ProbaPro-ExtraLightItalic.woff') format('woff'),
    url('ProbaPro-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Proba Pro SmBd';
  src: url('ProbaPro-SemiBold.eot');
  src: local('Proba Pro SemiBold'), local('ProbaPro-SemiBold'),
    url('ProbaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-SemiBold.woff2') format('woff2'), url('ProbaPro-SemiBold.woff') format('woff'),
    url('ProbaPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro Md';
  src: url('ProbaPro-MediumItalic.eot');
  src: local('Proba Pro Medium Italic'), local('ProbaPro-MediumItalic'),
    url('ProbaPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-MediumItalic.woff2') format('woff2'),
    url('ProbaPro-MediumItalic.woff') format('woff'),
    url('ProbaPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('ProbaPro-BoldItalic.eot');
  src: local('Proba Pro Bold Italic'), local('ProbaPro-BoldItalic'),
    url('ProbaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-BoldItalic.woff2') format('woff2'), url('ProbaPro-BoldItalic.woff') format('woff'),
    url('ProbaPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Proba Pro Lt';
  src: url('ProbaPro-LightItalic.eot');
  src: local('Proba Pro Light Italic'), local('ProbaPro-LightItalic'),
    url('ProbaPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-LightItalic.woff2') format('woff2'),
    url('ProbaPro-LightItalic.woff') format('woff'),
    url('ProbaPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proba Pro Th';
  src: url('ProbaPro-ThinItalic.eot');
  src: local('Proba Pro Thin Italic'), local('ProbaPro-ThinItalic'),
    url('ProbaPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-ThinItalic.woff2') format('woff2'), url('ProbaPro-ThinItalic.woff') format('woff'),
    url('ProbaPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('ProbaPro-Regular.eot');
  src: local('Proba Pro Regular'), local('ProbaPro-Regular'),
    url('ProbaPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-Regular.woff2') format('woff2'), url('ProbaPro-Regular.woff') format('woff'),
    url('ProbaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('ProbaPro-Italic.eot');
  src: local('Proba Pro Italic'), local('ProbaPro-Italic'),
    url('ProbaPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('ProbaPro-Italic.woff2') format('woff2'), url('ProbaPro-Italic.woff') format('woff'),
    url('ProbaPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
