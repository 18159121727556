.size {
  height: 200px;
  width: 200px;
}

.main {
  display: grid;
  margin: 0 30px 30px;
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 30px;
  height: 100%;
  grid-area: article;
}
.footer {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}
.footer-top {
  font-weight: bold;
}
.footer div {
  margin: 10px 0px;
}
.footer div span {
  color: #424752;
  margin-top: 20px;
  font-weight: 400;
}
.simple-button{
  padding: 10px 20px;
  background-color: #004bc1;
  color: white;
  border: 1px solid #004bc1;
  border-radius: 5px;
  font-size: 18px;
}

.auth {
  justify-self: space-around;
  grid-template-areas: '. . . content content content content . . . .';
}
.mainBlock {
  grid-area: content;
  align-self: center;
  max-width: 430px;
  width: 100%;
  margin: 0 auto;
}
.checkButton {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  font-size: 20px;
  padding: 2px;
  color: white;
  background-color: #004bc1;
  border: none;
}
.editButton{
  /* background-color: #004bc1;x */
  color: white;
  width: 24px;
  height:16px;
  border: none;
  border-radius: 10px;
  position: relative;
  background-image: url(./assets/icons/Edit.svg);
  background-position:center;
  background-size: cover;
}


.create-application .selectSpan select{
  width: auto;
  font-weight: 900;
  text-decoration: underline;
  padding: 15px 0px 14px 10px;
}
.create-application .selectSpan select:hover{
  background-color: #c2c5cb;
}
.errorBg{
  background-color: rgba(255, 0, 0, 0.2);
}
.warningBg{
  background-color: rgba(255, 255, 0, 0.2);
}
.acceptBg{
  background-color: rgba(0, 255, 0, 0.2);
}

.table-scroll {
  position: fixed; /* Фиксация внизу */
  bottom: 0;
  background-color: #304f80;
  height: 15px; /* Цвет фона для визуального отделения */
  width: 100%;
  overflow-x: auto; /* Горизонтальный скролл */
  border-top: 1px solid black;
}

.table-scroll .scroll-helper {
  width: 300%; /* Ширина для отображения горизонтального скролла */
  height: 15px; /* Минимальная высота */
}
.footerDiv{
  width: 80%;
  color: '#6D727C';
}
.closeIconButton{
position: absolute;
right: 10px;
top: 0px;
font-size: 20px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
width: 30px;
height: 30px;
background-color: #004bc1;
color: white;
line-height: normal;
text-align: center;
cursor: pointer;
}
.closeIconButton:hover{
  background-color: #ffe358;
}
.spinner div {
	box-sizing: border-box !important;
	display: block !important;
	position: absolute !important;
	width: 48px !important;
	height: 48px !important;
	margin: 8px !important;
	border: 8px solid #004bc1 !important;
	border-radius: 50% !important;
	background: none !important;
	animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #004bc1 transparent transparent transparent !important;
}
.spinner div:nth-child(1) {
	animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
	animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
	animation-delay: -0.15s;
}
.spinner {
	/* display: inline-block !important; */
	position: relative !important;
	width: 80px;
	height: 80px;
	margin: 0 auto;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
   }
	100% {
		transform: rotate(360deg);
   }
}
.loginHeader {
  text-align: center;
  margin-bottom: 60px;
}
tr{
  border-bottom: 1px solid black;
}

.labelText {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  margin-top: 25px;
  line-height: 20px;
}
.notification-item.readed {
  background-color: #f5f7fa;
}
.notification-item.readed .subject {
  font-weight: normal;
}
.notification-item:hover {
  background-color: #304f80;
  color: #ffce01;
  cursor: pointer;
}
.notification-item.selected {
  color: white;
  background-color: #304f80;
}

.notification-item .subject {
  font-weight: bold;
}
.notification-button {
  background: #f5f7fa;
  border-radius: 20px;
  border: none;
  color: #004bc1;
  padding: 5px 10px;
  margin: 20px 0 10px;
}
.notification-button:hover {
  color: #fff;
  background-color: #004bc1;
}
.labelText-top {
  margin-top: 0px;
}

input,.labelText .profileSelect {
  margin-top: 5px;
  border: 2px solid #1d1d1b;
  height: 50px;
  /* min-width: 400px; */
  max-width: 450px;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
  padding-left: 13px;
}

.add-comment {
  width: 700px;
  display: flex;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.add-comment input {
  width: 430px;
}

.inputInvalid {
  border-color: #b10e1e;
}
.add-comment button .delete-icon {
  right: 0;
  bottom: 0;
  background-image: url(./assets/icons/Delete-hover.svg);
}
.round-comment {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  border: none;
  background: #f5f7fa;
}
.add-comment .round-comment:hover {
  background-color: #004bc1;
}
.add-comment .round-comment:hover .delete-icon {
  background-image: url(./assets/icons/Delete.svg);
}

input:disabled {
  background: #c2c5cb;
  border-color: #c2c5cb;
  color: #6d727c;
}

.txt-input {
  padding-right: 129px;
  box-sizing: border-box;
  width: 100%;
}

.psw-input {
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
}

.validError {
  color: #b10e1e;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.inputValidError {
  color: #b10e1e;
}
.inputValidError input {
  border: red 1px dotted;
}

.btn {
  position: relative;
  max-width: 430px;
}

.txt-button-small {
  border: none;
  position: absolute;
  background: #ffffff;
  border-radius: 20px;
  color: #004bc1;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 10px;
  right: 7px;
  bottom: 11px;
}
.txt-button-small:hover {
  background: #004bc1;
  color: #ffffff;
}
.txt-button-small:disabled {
  background: #ffffff;
  color: #4b4c4e;
  cursor: not-allowed;
}
.iterator {
  margin-bottom: 25px;
  margin-top: 36px;
  text-align: center;
  /* font-weight:bold; */
  position: relative;
  color: #6d727c;
  font-size: 16px;
  line-height: 20px;
}
.iterator::before {
  position: absolute;
  content: '';
  width: 45%;
  height: 0px;
  border-top: 1px solid #6d727c;
  left: 0;
  top: 50%;
}
.iterator::after {
  position: absolute;
  content: '';
  width: 45%;
  height: 1px;
  border-top: 1px solid #6d727c;
  right: 0;
  top: 50%;
}

.btn-primary {
  border: none;
  background: #004bc1;
  color: #ffffff;
  font-weight: 600;
}
.btn-secondary:hover {
  background: #ffce01;
}
.btn-secondary {
  border: none;
  background: #ffe358;
  color: #1d1d1b;
  font-weight: 600;
}

.text-btn-primary {
  font-size: 20px;
  padding: 12px 15px;
  margin-top: 50px;
  width: 100%;
}
.reportPageFilter{
  grid-area: tab;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.reportPageFilter .text-btn-primary{
  margin-top: 20px;
}
.primary:disabled:hover {
  background: #c2c5cb;
  cursor: auto;
}
.btn-primary:hover {
  background: #304f80;
}
.btn-primary:disabled {
  background: #c2c5cb;
  border: 2px solid #c2c5cb;
}
button:focus,
input:focus,
.auth-tab-item:focus {
  outline: 2px dotted #6d727c;
  outline-offset: 3px;
}

.text-btn-tetriary {
  background: #ffffff;
  border: 2px solid #004bc1;
  color: #004bc1;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 15px;
  margin-top: 50px;
}

.text-btn-tetriary:hover {
  background: #4085bf;
  border-color: #4085bf;
  color: #ffffff;
}

.icon-text-btn-primary {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px 10px 44px;
  border-radius: 20px;
  height: 40px;
  min-width: 180px;
  position: relative;
}
.icon-text-btn-primary::after {
  content: url(./assets/icons/Plus.svg);
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 5px;
}
.exit {
  width: 105px;
  margin-left: 50px;
}
.exit::after {
  content: url(./assets/icons/Exit.svg);
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 5px;
}
.exit:hover::after {
  content: url(./assets/icons/Exit-hover.svg);
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 5px;
}

.td-pos {
  position: relative;
}
.td-pos .icon {
  visibility: hidden;
  bottom: 10px;
}
.selected .td-pos .icon {
  visibility: visible;
}

.assign-specialist .icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  position: static;
  visibility: hidden;
}

.assign-specialist .selected .icon {
  visibility: visible;
}

.button-control {
  display: flex;
  justify-content: space-between;
}
.button-control button {
  width: 47%;
}
.icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  bottom: 5px;
  cursor: pointer;
}
.icon-row .icon {
  bottom: 12px;
}
.icon-text-btn-secondary:disabled{
  background-color: #f5f7fa;
  color: #707070;
}
.icon-text-btn-secondary:disabled:hover{
  background-color: #f5f7fa;
  color: #707070;
}

.icon-text-btn-secondary .icon {
  right: unset;
  left: 7px;
  top: 0px;
}
.loginTextBlock{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:15px;
    margin-bottom: 20px;
    margin-top: -20px;
}
.loginTextBlock .loginCheck{
    cursor: pointer;
    width: 40px;
    height: 40px;
}
.loginText{
    font-size: 20px;
    padding: 10px 0px;
    text-align:justify;
}
.password-icon {
  background-image: url(./assets/icons/Eye.svg);
}
.password-icon-noActive {
  background-image: url(./assets/icons/Eye-Cross.svg);
}

.sidebar-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.search-icon {
  background-image: url(./assets/icons/Search.svg);
}
.bgAction{
  background-color: seashell;
}

.prompt {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #6d727c;
}
div.prompt {
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar-text {
  margin-top: 10px;
  color: #1d1d1b;
  font-size: 16px;
  line-height: 20px;
  margin-left: 15px;
}

.hugeText {
  font-weight: bold;
  font-size: 100px;
  line-height: 100px;
  text-align: center;
  margin-bottom: 30px;
}

.normalText {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.history-item {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}
.history-date {
  margin-right: 30px;
  width: 150px;
  position: relative;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.history-date::after {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #c2c5cb;
  position: absolute;
  right: -15px;
  top: 3px;
}
.history-info {
  font-size: 16px;
  line-height: 20px;
  color: #6d727c;
}
.history-info h5 {
  color: #1d1d1b;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.sidebar {
  width: 140px;
  grid-area: sidebar;
  background-color: #304f80;
  font-family: 'Proba Pro SmBd';
  z-index: 10;
  position: fixed;
  overflow: auto;
  height: 100%;
  /* visibility: hidden; */
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-align: center;
}
.sidebar-nav:hover {
  color: #ffce01;
  background-color: #234161;
}
a.sidebar-nav.selected:hover {
  color: #234161;
  background-color: white;
}

.sidebar-logo {
  background-image: url(./assets/icons/Gerb.svg);
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 70px;
  padding-bottom: 70px;
}
.icon-row {
  position: relative;
}
.icon-row .accept-icon {
  right: 80px;
}
.icon-row .comment-icon {
  right: 45px;
}
.applications-icon {
  background-image: url(./assets/icons/Applications.svg);
}
.exit-icon {
  background-image: url(./assets/icons/Applications.svg);
}

.ready-icon {
  cursor: auto;
  background-image: url(./assets/icons/Ready.svg);
}
.comment-item {
  display: flex;
  margin: 45px 0 0 0;
}
.comment-item-text {
  padding-left: 15px;
  font-size: 16px;
  line-height: 20px;
  color: #1d1d1b;
}
.comment-item-text h5 span {
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.comment-item-text label {
  color: #6d727c;
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-block;
}

.add-icon,  .declineTop-icon{
  height: 30px;
  width: 30px;
  position: absolute;
  left: 5px;
  top: 0px;
}
.add-icon{
  background-image: url(./assets/icons/Add.svg);
}
.declineTop-icon{
  top: 5px;
  background-image: url(./assets/icons/Decline.svg);
}

.icon-text-btn-small:hover .add-icon,
.icon-text-btn-secondary:hover .add-icon {
  background-image: url(./assets/icons/Add-hover.svg);
}
.accept-icon {
  background-image: url(./assets/icons/Accept.svg);
}
.comment-icon {
  background-image: url(./assets/icons/Comment.svg);
}
.comment-icon:hover {
  background-image: url(./assets/icons/Comment-hover.svg);
}
.decline-icon {
  background-image: url(./assets/icons/Decline.svg);
}
.decline-icon:hover {
  background-image: url(./assets/icons/Decline-hover.svg);
}
.return-icon {
  background-image: url(./assets/icons/Return.svg);
}
.dismiss-icon {
  background-image: url(./assets/icons/Dismiss.svg);
}
.icon-text-btn-secondary:hover .return-icon {
  background-image: url(./assets/icons/Return-hover.svg);
}
.icon-text-btn-secondary:hover .dismiss-icon {
  background-image: url(./assets/icons/Dismiss-hover.svg);
}

.roster-icon {
  background-image: url(./assets/icons/Roster.svg);
}
.login-icon {
  background-image: url(./assets/icons/Login.svg);
}
.organisations-icon {
  background-image: url(./assets/icons/Organisations.svg);
}
.directories-icon {
  background-image: url(./assets/icons/Directories.svg);
}
.message-icon {
  background-image: url(./assets/icons/Message.svg);
}
.unread {
  position: relative;
}
.unread::after {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #b10e1e;
  content: '';
}

.profile-icon {
  background-image: url(./assets/icons/Profile.svg);
}
.mates-icon {
  background-image: url(./assets/icons/Mates.svg);
}
.organisation-subheader-list-item .mates-icon.icon {
  background-image: url(./assets/icons/Mates-organisations.svg);
  right: 90px;
}
.public-table-cust .mates-icon.icon {
  background-image: url(./assets/icons/Mates-organisations.svg);
  right: 90px;
}
.public-table-cust.short{
min-width: max-content;
}
.ready-icon {
  cursor: auto;
  background-image: url(./assets/icons/Ready.svg);
}
.organisation-subheader-list .mates-icon {
  background-image: url(./assets/icons/Mates-organisations.svg);
}
.organisation-subheader-list:hover .mates-icon {
  background-image: url(./assets/icons/Mates.svg);
}
.sidebar-nav:hover .applications-icon {
  background-image: url(./assets/icons/Applications-hover.svg);
}
.sidebar-nav:hover .roster-icon {
  background-image: url(./assets/icons/Roster-hover.svg);
}
.sidebar-nav:hover .login-icon {
  background-image: url(./assets/icons/Login-hover.svg);
}
.sidebar-nav:hover .mates-icon {
  background-image: url(./assets/icons/Mates-hover.svg);
}
.sidebar-nav:hover .organisations-icon {
  background-image: url(./assets/icons/Organisations-hover.svg);
}
.sidebar-nav:hover .directories-icon {
  background-image: url(./assets/icons/Directories-hover.svg);
}
.sidebar-nav:hover .message-icon {
  background-image: url(./assets/icons/Message-hover.svg);
}
.sidebar-nav:hover .profile-icon {
  background-image: url(./assets/icons/Profile-hover.svg);
}

.readed-icon {
  background-image: url(./assets/icons/Readed.svg);
}

.sidebar-nav.selected:hover .applications-icon {
  background-image: url(./assets/icons/Applications-selected.svg);
}
.sidebar-nav.selected:hover .roster-icon {
  background-image: url(./assets/icons/Roster-selected.svg);
}
.sidebar-nav.selected:hover .login-icon {
  background-image: url(./assets/icons/Login-selected.svg);
}
.sidebar-nav.selected:hover .mates-icon {
  background-image: url(./assets/icons/Mates-selected.svg);
}

.sidebar-nav.selected:hover .organisations-icon {
  background-image: url(./assets/icons/Organisations-selected.svg);
}
.sidebar-nav.selected:hover .directories-icon {
  background-image: url(./assets/icons/Directories-selected.svg);
}
.sidebar-nav.selected:hover .message-icon {
  background-image: url(./assets/icons/Message-selected.svg);
}
.sidebar-nav.selected:hover .profile-icon {
  background-image: url(./assets/icons/Profile-selected.svg);
}

.filter-button {
  color: #004bc1;
  background: #f5f7fa;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  padding: 2px 10px 2px 38px;
  position: relative;
}

.filter-button .delete-icon.icon {
  left: 5px;
  top: 2px;
  height: 20px;
  width: 20px;
  background-image: url(./assets/icons/Delete-hover.svg);
}

.filter-button:hover {
  background: #004bc1;
  color: white;
}

.filter-button:hover .delete-icon.icon,
.filter-button:hover .delete-icon:hover {
  background-image: url(./assets/icons/Delete.svg);
  height: 20px;
  width: 20px;
}
.accept-icon:hover {
  background-image: url(./assets/icons/Accept-hover.svg);
}

.selected.sidebar-nav {
  background-color: #ffffff;
  color: #304f80;
}
.selected.sidebar-nav:hover {
  color: #ffce01;
}
.selected .applications-icon {
  background-image: url(./assets/icons/Applications-selected.svg);
}
.selected .roster-icon {
  background-image: url(./assets/icons/Roster-selected.svg);
}
.selected .mates-icon,
.organisation-subheader-list-item .mates-icon:hover {
  background-image: url(./assets/icons/Mates-selected.svg);
}
.selected .login-icon {
  background-image: url(./assets/icons/Login-selected.svg);
}
.selected .organisations-icon {
  background-image: url(./assets/icons/Organisations-selected.svg);
}
.selected .directories-icon {
  background-image: url(./assets/icons/Directories-selected.svg);
}
.selected .message-icon {
  background-image: url(./assets/icons/Message-selected.svg);
}
.selected .profile-icon {
  background-image: url(./assets/icons/Profile-selected.svg);
}

.sidebar-nav:focus-visible {
  color: #ffce01;
  background-color: #234161;
  outline: none;
}

.sidebar-nav:focus-visible .applications-icon {
  background-image: url(./assets/icons/Applications-hover.svg);
}
.sidebar-nav:focus-visible .roster-icon {
  background-image: url(./assets/icons/Roster-hover.svg);
}
.sidebar-nav:focus-visible .login-icon {
  background-image: url(./assets/icons/Login-hover.svg);
}
.sidebar-nav:focus-visible .organisations-icon {
  background-image: url(./assets/icons/Organisations-hover.svg);
}
.sidebar-nav:focus-visible .directories-icon {
  background-image: url(./assets/icons/Directories-hover.svg);
}
.sidebar-nav:focus-visible .message-icon {
  background-image: url(./assets/icons/Message-hover.svg);
}
.sidebar-nav:focus-visible .profile-icon {
  background-image: url(./assets/icons/Profile-hover.svg);
}
.sidebar-nav:focus-visible .mates-icon {
  background-image: url(./assets/icons/Mates-hover.svg);
}

.App {
  display: grid;
  grid-template-areas: 'sidebar article ';
  grid-template-columns: 120px 11fr;
  grid-row-gap: 10px;
  height: 100vh;
  margin: 0;
}
.link {
  display: block;
  text-align: center;
  font-family: 'Proba Pro SmBd';
  font-size: 20px;
  line-height: 20px;
  color: #004bc1;
  margin-top: 30px;
  margin-bottom: 80px;
  cursor: pointer;
}
.link:hover {
  color: #234161;
  text-decoration: underline;
}

.public-page {
  grid-template-areas:
    'h3 h3 h3 h3 h3 h3 h3 h3 menu menu menu '
    'h1 h1 h1 h1 h1 h1 . search search search search'
    'subtab subtab subtab subtab subtab subtab subtab subtab subtab subtab subtab'
    'table table table table table table table table table table table'
    'table table table table table table table table table table table'
    'footer footer footer footer footer footer footer footer footer footer footer';
}

.directory-page {
  grid-template-areas:
    'h3 h3 h3 h3 h3 h3 h3 menu menu menu menu '
    'h1 h1 h1 h1 h1 h1 . search search search search'
    'table table table table table table table table table table table'
    'table table table table table table table table table table table'
    'footer footer footer footer footer footer footer footer footer footer footer';
}
.profile-page {
  grid-template-areas:
    '. h3 h3 h3 h3 h3 h3 menu menu menu .'
    '. h1 h1 h1 h1 h1 h1 h1 h1 h1 .'
    '. btn . . . . . . . . .'
    '. img img img img . data data data data .'
    '. contact contact contact contact .  data data data data .'
    '. psw psw psw psw .  data data data data .';
}

.auth-page {
  grid-template-areas:
    'h3 h3 h3 h3 h3 h3 h3 menu menu menu menu'
    'h1 h1 h1 h1 h1 h1 . search search search search'
    'btn btn . . . . . . . . .'
    'tab tab tab tab tab tab tab tab tab tab tab'
    'subtab subtab subtab subtab subtab subtab subtab subtab subtab subtab subtab'
    'table table table table table table table table table table table';
}

.application-page {
  grid-template-areas:
    '. h3 h3 h3 h3 h3 h3 menu menu . .'
    '. h1 h1 h1 h1 h1 . .  .  .  . '
    '. label label label label label  . .  .  .  . '
    '. btn btn btn btn btn btn btn btn . .'
    '. tab tab tab tab tab tab tab tab . .'
    '. table table table table table table table table . .';
}

.public-page,
.auth-page {
  grid-template-rows: repeat(5, min-content);
  text-align: left;
}

.public-sub-header {
  grid-area: h3;
  margin-top: 35px;
  margin-bottom: 25px;
  /* flex-wrap: wrap; */
}
.create-app {
  grid-area: btn;
  margin-bottom: 50px;
}
.btn-tab {
  display: flex;
  min-width: 440px;
}
.btn-tab .icon-text-btn-primary {
  margin-left: 10px;
  max-width: 220px;
}
.labelText select {
  border: none;
  height: 30px;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
}
.btn-tab button {
  margin-right: 30px;
}
.auth-menu {
  grid-area: menu;
  justify-self: right;
  align-self: center;
  margin-top: 15px;
}

.auth-tab {
  font-family: 'Proba Pro SmBd';
  grid-area: tab;
  display: flex;
  color: #004bc1;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #c2c5cb;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 35px;
}
.auth-subtab {
  font-family: 'Proba Pro SmBd';
  grid-area: subtab;
  display: flex;
  color: #004bc1;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #c2c5cb;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 35px;
  margin-top: -25px;
}
.auth-tab-item {
  color: #004bc1;
  font-size: 18px;
  line-height: 20px;
  margin-left: 6px;
  margin-right: 34px;
  align-self: center;
  background: none;
  border: none;
}
.auth-tab-item:hover,
.auth-tab-item.selected {
  color: #1d1d1b;
  position: relative;
  cursor: pointer;
}

.icon-text-btn-secondary {
  font-family: 'Proba Pro SmBd';
  border: none;
  padding: 10px 20px 10px 44px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  max-width: 400px;
  max-height: 40px;
  position: relative;
  background: #f5f7fa;
  color: #004bc1;
}

.icon-text-btn-small {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Proba Pro SmBd';
  border-radius: 20px;
  position: relative;
  border: none;
  padding: 5px 10px 5px 37px;
  color: #004bc1;
  background: #f5f7fa;
}
.icon-text-btn-small:hover {
  background: #004bc1;
  color: #ffffff;
}
.comm {
  margin: 45px 0 20px;
}
.application-span {
  margin-top: 8px;
  display: inline-block;
  color: #004bc1;
  cursor: pointer;
  position: relative;
}
.form a.document-link{
  width: 75%;
}

.form-switch {
  position: absolute;
  right: 60px;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.labelText.form-switch {
  position: unset;
}
.sport-elements {
  font-size: 18px;
  line-height: 20px;
  color: #1d1d1b;
  border-bottom: 1px solid #c2c5cb;
  padding: 10px 0 10px 5px;
}
.sports-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 130px;
  content: url('./assets/icons/Sports.svg');
  top: 10px;
}
.sports-icon:hover {
  content: url('./assets/icons/Sports-hover.svg');
}
.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}
.mate-item {
  cursor: pointer;
}

a.mate-item {
  color: #1d1d1b;
}

.mate-item:hover {
  text-decoration: underline;
}
.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
.documentInput {
  display: none;
}
.documentInput input#file-upload-button {
}
.create-application.comm {
  display: flex;
  flex-direction: row;
}
.create-label-doc {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  color: #6d727c;
}
.create-label-doc:last-child {
  margin-bottom: 40px;
}
.dow {
  padding: 10px 15px;
  max-width: fit-content;
  margin-top: 15px;
  cursor: pointer;
  vertical-align: middle;
}
.document-link {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  position: relative;
  margin-left: 25px;
  margin-top: 15px;
  color: #1d1d1b;
  width: fit-content;
}
.doc-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -40px;
}
.document-link::before {
  position: absolute;
  content: url('./assets/icons/Documents.svg');
  width: 30px;
  height: 30px;
  left: -35px;
  top: -7px;
}
.icon-text-btn-secondary:hover {
  background: #004bc1;

  color: #ffffff;
}

.icon-text-btn-secondary.publicIcon {
  padding: 10px 20px;
}

.icon-text-btn-secondary:hover.docs::after {
  content: url('./assets/icons/Docs-hover.svg');
}
.icon-text-btn-secondary:hover.back::after {
  content: url('./assets/icons/Back-hover.svg');
}
.icon-text-btn-secondary:hover.filter::after {
  content: url('./assets/icons/FilterBtn-hover.svg');
}

.duplicate::after {
  content: url('./assets/icons/Dublicate.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}
.garbage::after {
  content: url('./assets/icons/Garbage.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}

.duplicate:hover::after {
  content: url('./assets/icons/Dublicate-hover.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}
.garbage:hover::after {
  content: url('./assets/icons/Garbage-hover.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}

.filter-on::before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  top: 5px;
  right: 10px;
  background-color: #ffce01;
  border-radius: 50%;
}
.filter::after {
  content: url('./assets/icons/FilterBtn.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}
.docs::after {
  content: url('./assets/icons/Docs.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}
.profile-page .back {
  margin-top: 35px;
}
.back::after {
  content: url('./assets/icons/Back.svg');
  height: 30px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 4px;
}
.avatar {
  font-family: 'Proba Pro SmBd';
  background: #004bc1;
  border: 1px solid #c2c5cb;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}
.avatar:hover {
  background: #304f80;
}
.avatar.avatar-picture {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.avatar-position {
  position: relative;
  /* width: 40px; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;
  font-weight: bold;
  gap: 8px;
  vertical-align: middle;
}
.directory {
  grid-area: table;
  height: 100%;
}
.create-application-container {
  grid-area: table;
  display: flex;
  flex-direction: column;
}
.create-application-cover {
  display: flex;
}
.create-application button {
  width: 250px;
  height: 30px;
  text-align: center;
  padding: 0;
  padding-left: 30px;
}
.imp {
  font-weight: bold;
  color: #1d1d1b;
}
.comm .print::after {
  top: 0;
}
.print::after {
  position: absolute;
  left: 12px;
  top: 6px;
  height: 30px;
  width: 30px;
  content: url(./assets/icons/Print.svg);
}
.print:hover::after {
  content: url(./assets/icons/Print-hover.svg);
}
.create-application {
  width: 675px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: #6d727c;
}
.create-application label {
  border-bottom: 1px solid #c2c5cb;
  display: flex;
  align-items: center;
}

.create-application input[type='date'] {
  color: #6d727c;
}

.application-block {
  position: absolute;
  z-index: 10000;
  width: 400px;
  height: 200px;
  background: white;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
}
.application-block-item {
  margin: 0 10px;
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 15px;
  width: 90%;
  border-bottom: 1px solid #c2c5cb;
}
.instruction {
  color: white;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
}
.instruction:hover {
  color: #ffce01;
}
h4.imp {
  margin-top: 40px;
  font-size: 18px;
  margin-bottom: 20px;
}
.create-application label select {
  border: none;
  width: 410px;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
}
.create-application label select:focus-visible {
  border: none;
}
.create-label,
.create-label input {
  color: #c2c5cb;
}
.create-label input:disabled {
  background: none;
  margin: 0;
  color: #707070;
}
.create-label {
}
.create-application h2 {
  margin: 40px 0;
}
.create-application label span {
  width: 205px;
  padding: 15px 0px 14px 10px;
}
.create-application label input {
  width: 425px;
  border: none;
  font-size: 18px;
  line-height: 20px;
  margin: 5px;
  /* border-bottom: 1px solid grey; */
}

.form {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  left: 0;
  width: 100%;
  height: 100vh;
}
.form-transparent {
  width: 100%;
  height: 100%;
  background: rgba(29, 29, 27, 0.5);
}
.form-value {
  width: 520px;
  height: 100%;
  display: flex;
  padding: 20px;
  overflow-y: auto;
  flex-direction: column;
  background: #ffffff;
}

.form-value h1 {
  text-align: left;
}

.form-subheader {
  font-family: 'Proba Pro SmBd';
  font-size: 24px;
  line-height: 40px;
  color: #6d727c;
  margin-bottom: 20px;
}
.form-subheader-label {
  color: #6d727c;
}
.icon-text-btn-secondary .add-icon {
  top: 5px;
}
.organisation {
  grid-area: table;
}
.round-btn-secondary {
  min-width: 40px;
  height: 40px;
  box-sizing: border-box;
  background: #f5f7fa;
  border-radius: 50%;
  position: relative;
  padding: 0;
  border: none;
}
.round-btn-secondary:hover {
  background: #004bc1;
}
.close::after {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 5px;
  top: 5px;
  content: url(./assets/icons/Close.svg);
}
.close:hover::after {
  content: url(./assets/icons/Close-hover.svg);
}
.alert-label {
  position: fixed;
  bottom: 30px;
  left: 280px;
  background-color: #ffce01;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
  width: 600px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.alert-label-buttons {
  align-items: center;
  display: flex;
}
.judge-buttons {
  width: 40px;
  position: relative;
}
.judge-buttons .edit-icon {
}
.organisation-subheader {
  font-family: 'Proba Pro';
  color: #6d727c;
  width: 100%;
  background-color: #f5f7fa;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 0 5px 15px;
  border-bottom: 2px solid #1d1d1b;
  cursor: pointer;
}
.videoPlayerContainer svg {
  fill: var(--apColorLight);
  width: 80px;
  height: 80px;
  cursor: pointer;
  position: absolute;
}
.videoPlayerContainer video {
  height: initial;
}
.videoPlayerContainer {
  margin: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 800px;
  height: 444px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 18px;
  /* background: linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)); */
}
.organisation-subheader-list {
  font-family: 'Proba Pro SmBd';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1d1d1b;
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid #c2c5cb;
  position: relative;
}
.organisation-subheader-list i.edit-icon {
  right: 50px;
}
.organisation-subheader-list:hover i.edit-icon {
  right: 50px;
}
.organisation-subheader-list i.mates-icon {
  right: 90px;
}
.organisation-subheader-list:hover i.mates-icon {
  right: 90px;
}
.organisation-subheader-list.active {
  color: #ffffff;
  background: #304f80;
}
.organisation-subheader-list.first-line:hover {
  cursor: auto;
  background: #fff;
  color: #1d1d1b;
}
.organisation-subheader-list:hover {
  color: #ffffff;
  background: #304f80;
  cursor: pointer;
}

.directory-subheader {
  font-family: 'Proba Pro SmBd';
  width: 100%;
  background-color: #e4edfa;
  font-size: 24px;
  line-height: 40px;
  padding: 5px 0 5px 15px;
  border-bottom: 2px solid #1d1d1b;
  cursor: pointer;
}
.directory-subheader-list {
  font-family: 'Proba Pro';
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #1d1d1b;
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid #c2c5cb;
  position: relative;
}

.organisation-subheader-list-item {
  margin-left: 40px;
}
.organisation h2 {
  margin-bottom: 40px;
  margin-top: 60px;
}
.edit-icon {
  right: 50px;
  background-image: url(./assets/icons/Edit.svg);
}
.edit-icon:hover {
  background-image: url(./assets/icons/Edit-hover.svg);
}
.organisation-subheader-list .edit-icon {
  right: 10px;
}
.active .edit-icon,
.organisation-subheader-list:hover .edit-icon {
  right: 10px;
  background-image: url(./assets/icons/Edit-active.svg);
}
.delete-icon {
  background-image: url(./assets/icons/Delete.svg);
}
.organisation-subheader-list .edit-icon:hover {
  right: 10px;
  background-image: url(./assets/icons/Edit-active-hover.svg);
}
.delete-icon:hover {
  background-image: url(./assets/icons/Delete-hover.svg);
}
.organisation-subheader-list-item .directory-subheader-list:last-child {
  margin-bottom: 0px;
}
.directory-subheader-list:last-child {
  margin-bottom: 65px;
}

.document-type-hint {
  color: #6d727c;
  position: absolute;
  width: 500px;
  top: 50%;
  transform: translateY(-50%);
  left: 210px;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Proba Pro';
}

.logMenu {
  display: none;
  font-family: 'Proba Pro';
  width: 205px;
  height: 230px;
  position: absolute;
  background: white;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  cursor: auto;
  right: 0;
  top: 50px;
  z-index: 111111;
  color: #1d1d1b;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.logMenu.active {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.auth-tab-item:hover.auth-tab-item::after,
.auth-tab-item.selected.auth-tab-item::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -17px;
  left: 0;
  height: 4px;
  background-color: #004bc1;
}

.public-sub-header h3,
.public-header,
.public-header h1 {
  text-align: left;
}
.public-header label {
  color: #6d727c;
}

.public-header {
  grid-area: h1;
  margin-bottom: 60px;
}
.public-table-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.public-table-overlay td {
  text-align: center;
  color: #fff;
  padding: 15px;
}
.public-table {
  grid-area: table;
  min-height: fit-content;
  box-sizing: border-box;
  /* overflow: auto; */
}
.public-table-add {
  width: 100%;
}

.public-table-cust {
  overflow-y: auto;
  min-width: 1338px;
  min-height: 80px;
  width: 100%;
  position: relative;
}
.public-table-cust.complicated {
  font-family: 'Proba Pro SmBd';
  line-height: 20px;
}
.public-table-cust tfoot {
  background: #f5f7fa;
  height: 40px;
  width: 100%;
  border-top: 2px solid black;
}

.public-table-cust.roster {
  /* position: relative; */
  min-width: max-content;
  width: 100%;
}
.public-table-cust.roster  tbody{
  overflow-x: scroll;
}

.roster th:first-child,
.roster td:first-child {
  text-align: center;
}
.search {
  grid-area: search;
  margin-bottom: 20px;
}
.search .btn {
  float: right;
  width: 100%;
}
.search-input {
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
}
.image-block {
  position: relative;
  display: flex;
  align-items: center;
  grid-area: img;
  justify-items: center;
  height: 200px;
  padding-bottom: 10px;
  margin-bottom: 60px;
}
.application-image-block {
  margin-top: 260px;
  margin-left: 35px;
}
.create-application-container div table {
  width: 100%;
}
.application-image-block .image-block label {
  padding-left: 0;
  margin-top: 10px;
}
.section-head {
  margin-top: 65px;
  margin-bottom: 40px;
}
.application-image-block .image-block {
  flex-direction: column;
  height: 270px;
}
.application-image-block .image-block::after {
  height: 240px;
  width: 180px;
}
.application-image-block .image-block img {
  width: 180px;
  height: 240px;
}
.image-block img {
  width: 200px;
  height: 200px;
  background-color: transparent;
  z-index: 0;
  object-fit: cover;
}

.password-block {
  grid-area: psw;
}

.contact-block {
  grid-area: contact;
}

.data-block {
  grid-area: data;
}

.password-block,
.contact-block,
.data-block {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 60px;
}

.image-block::after {
  content: 'фото';
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #f5f7fa;
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  color: #c2c5cb;
  z-index: -1;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 76px;
}

img[src='*'] {
  border: 1px solid #c2c5cb;
}

.image-block label {
  font-family: 'Proba Pro SmBd';
  color: #004bc1;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  padding-left: 25px;
}

.image-block label:hover {
  text-decoration: underline;
}

.image-upload {
  display: none;
}

.checkbox-column {
  width: 20px;
}

tr.active {
  background: #304f80;
  color: #ffffff;
}

.complicated tbody tr:hover {
  cursor: pointer;
  background: #304f80;
  color: #ffffff;
}

@media (max-width: 839px) {
  .alert-label {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    left: 0;
  }
  .alert-label button {
    margin-top: 10px;
  }
}

@media all and (max-width: 1665px) {
  .roster th,
  .roster td {
    max-width: 200px;
  }
}

@media all and (max-width: 1160px) {
  .public-table {
    overflow-x: auto;
  }
  .mainBlock {
    margin-top: 60px;
  }
  .sidebar-nav.open {
    margin-top: 60px;
  }
  .main {
    margin: 0 30px;
    margin-top: 60px;
    grid-template-columns: repeat(8, 1fr);
  }
  .auth {
    grid-template-areas: '. .   content content content content . .';
  }

  .public-page {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
    '. . . . menu menu menu menu'
      'h3 h3 h3 h3 h3 h3 h3 h3'
      'h1 h1 h1 h1 h1 search search search'
      'subtab subtab subtab subtab subtab subtab subtab subtab'
      'table table table table table table table table'
      'footer footer footer footer footer footer footer footer';
  }
  .profile-page {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      'h3 h3 h3 h3 menu menu menu menu'
      'h1 h1 h1 h1 h1 h1 h1 h1 '
      'btn . . . . . . .'
      'img img img img data data data data'
      'contact contact contact contact data data data data'
      'psw psw psw psw data data data data';
  }

  .auth-page {
    grid-template-areas:
      'h3 h3 h3 h3 menu menu menu menu'
      'h1 h1 h1 h1 h1 search search search'
      'btn btn btn btn . . . .'
      'tab tab tab tab tab tab tab tab'
      'table table table table table table table table';
  }
  .public-table-cust {
    overflow-x: auto;
  }
  .organisation {
    overflow-x: auto;
  }
  .App {
    grid-template-areas: ' article ';
    grid-template-columns: 100%;
  }
  .sidebar {
    visibility: hidden;
    height: 100%;
    width: 30vw;
  }
  .sidebar.open {
    visibility: visible;
  }
  .sidebar-open {
    width: 100%;
    position: absolute;
    height: 60px;
    left: 0px;
    top: 0px;
    background-color: #304f80;
    cursor: pointer;
  }

  .sidebar-open::before {
    position: absolute;
    width: 30px;
    height: 45px;
    left: 25px;
    top: 9px;
    content: '';
    background: url(./assets/icons/Gerb.svg);
    background-size: cover;
  }
  .sidebar-open::after {
    position: absolute;
    width: 30px;
    height: 45px;
    right: 25px;
    top: 9px;
    content: '';
    background: url(./assets/icons/Menu.svg);
    background-size: cover;
  }

  .public-table {
    overflow-x: auto;
  }

  @media all and (max-width: 720px) {
    .search .btn{
      margin-bottom: 20px;
    }
    .main {
      grid-template-columns: repeat(6, 1fr);
    }
    .footerDiv{
      width: 100%;
      color: '#6D727C';
    }    
    .application-block{
      left: 0;
    }
    .public-header{
      margin-bottom: 30px;
    }
    .form {
      width: 100%;
    }
    .form-transparent {
      width: 30%;
    }
    .auth {
      grid-template-areas: '. content content content content .';
    }
    .public-page {
      grid-template-areas:
        '. . menu menu menu menu'
        'h3 h3 h3 h3 h3 h3'
        'h1 h1 h1 h1 h1 h1'
        'search search search search search search'
        'subtab subtab subtab subtab subtab subtab'
        'table table table table table table '
        'footer footer footer footer footer footer';
    }
    .profile-page {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        '. menu menu menu menu .'
        '. h3 h3 h3 h3 .'
        '. h1 h1 h1 h1 . '
        '. btn . . . .'
        '. img img img img .'
        '. data data data data .'
        '. contact contact contact contact .'
        '. psw psw psw psw .';
    }

    .auth-page {
      grid-template-areas:
        'menu menu menu menu menu menu menu menu'
        'h3 h3 h3 h3 h3 h3 h3 h3'
        'h1 h1 h1 h1 h1 h1 h1 h1'
        'search search search search search search search search'
        'btn btn . . . . . .'
        'tab tab tab tab tab tab tab tab'
        'table table table table table table table table';
    }
    .sidebar {
      width: 60vw;
    }
  }
}

@media all and (max-width: 560px) {
  .reportPageFilter .labelText
  {
    width: 80%;
  }
  .reportPageFilter {
    margin-bottom: 40px;
  }
  .main {
    margin-left: 30px;
    grid-template-columns: repeat(4, 1fr);
  }

  .auth {
    grid-template-areas: ' content content content content  ';
  }
  .form {
    width: 100%;
  }
  .form-transparent {
    width: 0;
  }
  .public-page {
    grid-template-areas:
      'menu menu menu menu'
      'h3 h3 h3 h3'
      'h1 h1 h1 h1'
      'search search search search'
      'subtab subtab subtab subtab'
      'table table table table'
      'footer footer footer footer';
  }
  .auth-page {
    grid-template-areas:
      'menu menu menu menu '
      'h3 h3 h3 h3'
      'h1 h1 h1 h1'
      'search search search search'
      'btn btn . .'
      'tab tab tab tab'
      'subtab subtab subtab subtab'
      'table table table table';
  }
  input {
    min-width: 240px;
  }

  input.loginCheck{
    min-width: 40px;
  }
  .sidebar {
    width: 100vw;
  }
  .profile-page {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'menu menu menu menu'
      'h3 h3 h3 h3'
      'h1 h1 h1 h1'
      'btn . . .'
      'img img img img'
      'data data data data'
      'contact contact contact contact'
      'psw psw psw psw';
  }
}

.digital-signature-modal-overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
}

.digital-signature-modal {
    width: 800px;
    height: -webkit-fill-available;
    position: absolute;
    background: white;
    padding: 20px;
    margin: 50px 30%;
    box-sizing: border-box;
    text-align: center;
    overflow-y: auto;
}
.digital-signature-modal.withIframe{
  display: flex;
  flex-direction: column;
}
.withIframe button{
  width: 240px;
  justify-self: right;
  align-self: flex-end;
  margin-bottom: 20px;
}

#sign-widget-parent {
  width: 100%;
  height: 640px;
}
