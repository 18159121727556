html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img,
a {
  border: none;
  outline: none;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
a:focus-visible {
  text-decoration: none;
  outline: none;
}

body {
  font-family: 'Proba Pro';
  scrollbar-color: #f5f7fa #304f80;
  margin: 0;
}
button {
  font-family: 'Proba Pro SmBd';
}

h1 {
  font-size: 40px;
  font-family: 'Proba Pro SmBd';
  margin-bottom: 60px;
  line-height: 60px;
  text-align: center;
  color: #1d1d1b;
}

div h1 {
  margin-bottom: 20px;
}

h2 {
  font-family: 'Proba Pro SmBd';
  font-size: 30px;
  line-height: 40px;
  color: #1d1d1b;
}

h3 {
  font-family: 'Proba Pro';
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #1d1d1b;
}
.auth h3 {
  font-family: 'Proba Pro SmBd';
  margin-bottom: 15px;
  text-align: center;
  color: #6d727c;
}

button:hover {
  cursor: pointer;
}

thead {
  background: #f5f7fa;
  min-height: 50px;
  color: #6d727c;
  font-size: 16px;
  line-height: 20px;
  border-bottom: 2px solid #1d1d1b;
  min-width: 800px;
}

th span {
  position: relative;
}

tr th.selected {
  font-family: 'Proba Pro';
  font-weight: bold;
  color: #1d1d1b;
}

th span::after {
  position: absolute;
  content: url('./assets/icons/Filter.svg');
  width: 30px;
  height: 30px;
  top: 0;
  text-align: left;
}
th span.no-filter::after {
  visibility: hidden;
}

th:hover,
th.selected:hover {
  color: #004bc1;
  cursor: pointer;
}

th:hover span::after,
th.selected:hover span::after {
  content: url('./assets/icons/Filter-hover.svg');
}

span.two-rows::after {
  top: 27%;
}

th.selected span::after {
  content: url('./assets/icons/Filter-Selected.svg');
}
th.selected.desc span::after {
  content: url('./assets/icons/Filter-Selected-desc.svg');
}
th,
td {
  vertical-align: middle;
  padding: 10px 15px;
  font-weight: normal;
}
tbody tr {
  height: 65px;
  border-bottom: 1px solid #c2c5cb;
}

input[type='checkbox'] {
  width: 24px;
  height: 24px;
  border-color: #1d1d1b;
  background: #ffffff;
  border-radius: 2px;
}

@import './font/ProboPro/stylesheet.css';
